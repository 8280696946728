import React from 'react';
import { graphql } from 'gatsby';
import { SanityBlogTag, SanityBlogPost } from 'graphql-types';
import { BlogPageGenericTemplate } from '../components/Blog/blog-page-generic-template';
import { default as SEO } from '../components/SEO';

interface BlogTagTemplateProps {
  data: {
    tag: SanityBlogTag;
    posts: {
      edges: {
        node: SanityBlogPost;
      }[];
    };
  };
}

const BlogTagTemplate = ({ data }: BlogTagTemplateProps) => {
  const { tag } = data;

  if (!tag) {
    return null;
  }

  const posts = data.posts.edges.map(({ node }) => node).slice(0, 10) || [];

  return (
    <>
      <SEO pageTitle={tag.title} />
      <BlogPageGenericTemplate title={tag.title} posts={posts} />
    </>
  );
};

export const query = graphql`
  query BlogTagQuery($sanityID: String) {
    tag: sanityBlogTag(_id: { eq: $sanityID }) {
      title
    }
    posts: allSanityBlogPost(
      filter: {
        tags: { elemMatch: { _id: { eq: $sanityID } } }
        slug: { current: { ne: null } }
      }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...BlogPostCardInfo
        }
      }
    }
  }
`;

export default BlogTagTemplate;
